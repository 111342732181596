import { useCallback, useState } from 'react';

import { t } from 'localization';

export const useProjectNameHook = () => {
  const [name, setName] = useState('');
  const [nameError, setNameError] = useState('');

  const triggerValidation = useCallback(() => {
    const trimmedText = name?.trimStart();
    setNameError(prevState => {
      if (prevState && !(trimmedText?.length > 0)) {
        return t('CreateProject:SkillWayStart:ProjectNameRequired');
      }
      if (trimmedText.length > 30) {
        return t('CreateProject:SkillWayStart:ProjectNameTooLong');
      }
      return '';
    });
  }, [name]);

  const setNameHandler = useCallback(
    name => {
      const trimmedText = name?.trimStart();
      setName(trimmedText);
      triggerValidation();
    },
    [triggerValidation]
  );

  return {
    setNameHandler,
    triggerValidation,
    nameError,
    name,
    trimmedName: name?.trim(),
  };
};
