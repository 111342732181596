// tslint:disable
// @ts-nocheck
/**
 * Aimychat service API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as globalImportUrl from "url";
import globalAxios from "axios";
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, BaseAPI, RequiredError, } from "./base";
/**
 *
 * @export
 * @enum {string}
 */
export var ChartType;
(function (ChartType) {
    ChartType["CHATSCOUNT"] = "CHATS_COUNT";
    ChartType["AVERAGECONNECTTIME"] = "AVERAGE_CONNECT_TIME";
    ChartType["AVERAGEFIRSTMESSAGETIME"] = "AVERAGE_FIRST_MESSAGE_TIME";
    ChartType["AVERAGEDURATION"] = "AVERAGE_DURATION";
})(ChartType || (ChartType = {}));
/**
 * How clients are sorted in the \"Queue\" tab
 * @export
 * @enum {string}
 */
export var ClientQueueSorting;
(function (ClientQueueSorting) {
    ClientQueueSorting["ASC"] = "ASC";
    ClientQueueSorting["DESC"] = "DESC";
})(ClientQueueSorting || (ClientQueueSorting = {}));
/**
 *
 * @export
 * @enum {string}
 */
export var IntervalType;
(function (IntervalType) {
    IntervalType["DAY"] = "DAY";
    IntervalType["WEEK"] = "WEEK";
    IntervalType["MONTH"] = "MONTH";
})(IntervalType || (IntervalType = {}));
/**
 *
 * @export
 * @enum {string}
 */
export var OperatorRole;
(function (OperatorRole) {
    OperatorRole["AGENT"] = "AGENT";
    OperatorRole["ADMIN"] = "ADMIN";
})(OperatorRole || (OperatorRole = {}));
/**
 *
 * @export
 * @enum {string}
 */
export var SoundNotificationMode;
(function (SoundNotificationMode) {
    SoundNotificationMode["ALL"] = "ALL";
    SoundNotificationMode["ONLYNEWMESSAGES"] = "ONLY_NEW_MESSAGES";
    SoundNotificationMode["ONLYNEWCHATS"] = "ONLY_NEW_CHATS";
    SoundNotificationMode["NOTHING"] = "NOTHING";
})(SoundNotificationMode || (SoundNotificationMode = {}));
/**
 * AlfbApi - axios parameter creator
 * @export
 */
export const AlfbApiAxiosParamCreator = function (configuration) {
    return {
        /**
         * Get client
         * @param {string} clientId Client identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAlfbClient(clientId, options = {}) {
            // verify required parameter 'clientId' is not null or undefined
            if (clientId === null || clientId === undefined) {
                throw new RequiredError("clientId", "Required parameter clientId was null or undefined when calling getAlfbClient.");
            }
            const localVarPath = `/api/aimychat-be/alfb/clients/{clientId}`.replace(`{${"clientId"}}`, encodeURIComponent(String(clientId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get client list
         * @param {boolean} [isActive] return active clients
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAlfbClients(isActive, options = {}) {
            const localVarPath = `/api/aimychat-be/alfb/clients`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            if (isActive !== undefined) {
                localVarQueryParameter["isActive"] = isActive;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get alfb config
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAlfbConfig(options = {}) {
            const localVarPath = `/api/aimychat-be/alfb/config`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get current operator
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAlfbCurrentOperator(options = {}) {
            const localVarPath = `/api/aimychat-be/alfb/current-operator`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get unread chat by token
         * @param {string} token Operator place token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUnreadChatStats(token, options = {}) {
            // verify required parameter 'token' is not null or undefined
            if (token === null || token === undefined) {
                throw new RequiredError("token", "Required parameter token was null or undefined when calling getUnreadChatStats.");
            }
            const localVarPath = `/api/aimychat-be/alfb/tokens/{token}/operator-chats/unread`.replace(`{${"token"}}`, encodeURIComponent(String(token)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Join client alfb
         * @param {JoinClientDto} joinClientDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        joinClient(joinClientDto, options = {}) {
            // verify required parameter 'joinClientDto' is not null or undefined
            if (joinClientDto === null || joinClientDto === undefined) {
                throw new RequiredError("joinClientDto", "Required parameter joinClientDto was null or undefined when calling joinClient.");
            }
            const localVarPath = `/api/aimychat-be/alfb/clients/join`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "POST" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarHeaderParameter["Content-Type"] = "application/json";
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof joinClientDto !== "string" ||
                localVarRequestOptions.headers["Content-Type"] === "application/json";
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(joinClientDto !== undefined ? joinClientDto : {})
                : joinClientDto || "";
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * AlfbApi - functional programming interface
 * @export
 */
export const AlfbApiFp = function (configuration) {
    return {
        /**
         * Get client
         * @param {string} clientId Client identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAlfbClient(clientId, options) {
            const localVarAxiosArgs = AlfbApiAxiosParamCreator(configuration).getAlfbClient(clientId, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Get client list
         * @param {boolean} [isActive] return active clients
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAlfbClients(isActive, options) {
            const localVarAxiosArgs = AlfbApiAxiosParamCreator(configuration).getAlfbClients(isActive, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Get alfb config
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAlfbConfig(options) {
            const localVarAxiosArgs = AlfbApiAxiosParamCreator(configuration).getAlfbConfig(options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Get current operator
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAlfbCurrentOperator(options) {
            const localVarAxiosArgs = AlfbApiAxiosParamCreator(configuration).getAlfbCurrentOperator(options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Get unread chat by token
         * @param {string} token Operator place token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUnreadChatStats(token, options) {
            const localVarAxiosArgs = AlfbApiAxiosParamCreator(configuration).getUnreadChatStats(token, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Join client alfb
         * @param {JoinClientDto} joinClientDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        joinClient(joinClientDto, options) {
            const localVarAxiosArgs = AlfbApiAxiosParamCreator(configuration).joinClient(joinClientDto, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
    };
};
/**
 * AlfbApi - factory interface
 * @export
 */
export const AlfbApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         * Get client
         * @param {string} clientId Client identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAlfbClient(clientId, options) {
            return AlfbApiFp(configuration).getAlfbClient(clientId, options)(axios, basePath);
        },
        /**
         * Get client list
         * @param {boolean} [isActive] return active clients
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAlfbClients(isActive, options) {
            return AlfbApiFp(configuration).getAlfbClients(isActive, options)(axios, basePath);
        },
        /**
         * Get alfb config
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAlfbConfig(options) {
            return AlfbApiFp(configuration).getAlfbConfig(options)(axios, basePath);
        },
        /**
         * Get current operator
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAlfbCurrentOperator(options) {
            return AlfbApiFp(configuration).getAlfbCurrentOperator(options)(axios, basePath);
        },
        /**
         * Get unread chat by token
         * @param {string} token Operator place token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUnreadChatStats(token, options) {
            return AlfbApiFp(configuration).getUnreadChatStats(token, options)(axios, basePath);
        },
        /**
         * Join client alfb
         * @param {JoinClientDto} joinClientDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        joinClient(joinClientDto, options) {
            return AlfbApiFp(configuration).joinClient(joinClientDto, options)(axios, basePath);
        },
    };
};
/**
 * AlfbApi - object-oriented interface
 * @export
 * @class AlfbApi
 * @extends {BaseAPI}
 */
export class AlfbApi extends BaseAPI {
    /**
     * Get client
     * @param {string} clientId Client identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AlfbApi
     */
    getAlfbClient(clientId, options) {
        return AlfbApiFp(this.configuration).getAlfbClient(clientId, options)(this.axios, this.basePath);
    }
    /**
     * Get client list
     * @param {boolean} [isActive] return active clients
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AlfbApi
     */
    getAlfbClients(isActive, options) {
        return AlfbApiFp(this.configuration).getAlfbClients(isActive, options)(this.axios, this.basePath);
    }
    /**
     * Get alfb config
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AlfbApi
     */
    getAlfbConfig(options) {
        return AlfbApiFp(this.configuration).getAlfbConfig(options)(this.axios, this.basePath);
    }
    /**
     * Get current operator
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AlfbApi
     */
    getAlfbCurrentOperator(options) {
        return AlfbApiFp(this.configuration).getAlfbCurrentOperator(options)(this.axios, this.basePath);
    }
    /**
     * Get unread chat by token
     * @param {string} token Operator place token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AlfbApi
     */
    getUnreadChatStats(token, options) {
        return AlfbApiFp(this.configuration).getUnreadChatStats(token, options)(this.axios, this.basePath);
    }
    /**
     * Join client alfb
     * @param {JoinClientDto} joinClientDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AlfbApi
     */
    joinClient(joinClientDto, options) {
        return AlfbApiFp(this.configuration).joinClient(joinClientDto, options)(this.axios, this.basePath);
    }
}
/**
 * BillingApi - axios parameter creator
 * @export
 */
export const BillingApiAxiosParamCreator = function (configuration) {
    return {
        /**
         * Get tariff limits for operator place.
         * @param {number} accountId Account identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTariffLimits(accountId, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling getTariffLimits.");
            }
            const localVarPath = `/api/aimychat-be/accounts/{accountId}/billing/tariff-limits`.replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update tariff limits for operator place.
         * @param {number} accountId Account identifier
         * @param {TariffLimitsDto} tariffLimitsDto Operator place tariff limits
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTariffLimits(accountId, tariffLimitsDto, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling updateTariffLimits.");
            }
            // verify required parameter 'tariffLimitsDto' is not null or undefined
            if (tariffLimitsDto === null || tariffLimitsDto === undefined) {
                throw new RequiredError("tariffLimitsDto", "Required parameter tariffLimitsDto was null or undefined when calling updateTariffLimits.");
            }
            const localVarPath = `/api/aimychat-be/accounts/{accountId}/billing/tariff-limits`.replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "POST" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarHeaderParameter["Content-Type"] = "application/json";
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof tariffLimitsDto !== "string" ||
                localVarRequestOptions.headers["Content-Type"] === "application/json";
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(tariffLimitsDto !== undefined ? tariffLimitsDto : {})
                : tariffLimitsDto || "";
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * BillingApi - functional programming interface
 * @export
 */
export const BillingApiFp = function (configuration) {
    return {
        /**
         * Get tariff limits for operator place.
         * @param {number} accountId Account identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTariffLimits(accountId, options) {
            const localVarAxiosArgs = BillingApiAxiosParamCreator(configuration).getTariffLimits(accountId, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Update tariff limits for operator place.
         * @param {number} accountId Account identifier
         * @param {TariffLimitsDto} tariffLimitsDto Operator place tariff limits
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTariffLimits(accountId, tariffLimitsDto, options) {
            const localVarAxiosArgs = BillingApiAxiosParamCreator(configuration).updateTariffLimits(accountId, tariffLimitsDto, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
    };
};
/**
 * BillingApi - factory interface
 * @export
 */
export const BillingApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         * Get tariff limits for operator place.
         * @param {number} accountId Account identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTariffLimits(accountId, options) {
            return BillingApiFp(configuration).getTariffLimits(accountId, options)(axios, basePath);
        },
        /**
         * Update tariff limits for operator place.
         * @param {number} accountId Account identifier
         * @param {TariffLimitsDto} tariffLimitsDto Operator place tariff limits
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTariffLimits(accountId, tariffLimitsDto, options) {
            return BillingApiFp(configuration).updateTariffLimits(accountId, tariffLimitsDto, options)(axios, basePath);
        },
    };
};
/**
 * BillingApi - object-oriented interface
 * @export
 * @class BillingApi
 * @extends {BaseAPI}
 */
export class BillingApi extends BaseAPI {
    /**
     * Get tariff limits for operator place.
     * @param {number} accountId Account identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BillingApi
     */
    getTariffLimits(accountId, options) {
        return BillingApiFp(this.configuration).getTariffLimits(accountId, options)(this.axios, this.basePath);
    }
    /**
     * Update tariff limits for operator place.
     * @param {number} accountId Account identifier
     * @param {TariffLimitsDto} tariffLimitsDto Operator place tariff limits
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BillingApi
     */
    updateTariffLimits(accountId, tariffLimitsDto, options) {
        return BillingApiFp(this.configuration).updateTariffLimits(accountId, tariffLimitsDto, options)(this.axios, this.basePath);
    }
}
/**
 * E2ECheckApi - axios parameter creator
 * @export
 */
export const E2ECheckApiAxiosParamCreator = function (configuration) {
    return {
        /**
         * Method for E2ECheck.
         * @param {boolean} loggingEnabledE2ECheck enable/disable logging for e2e check
         * @param {string} [zRequestId] Request id header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        e2ECheck(loggingEnabledE2ECheck, zRequestId, options = {}) {
            // verify required parameter 'loggingEnabledE2ECheck' is not null or undefined
            if (loggingEnabledE2ECheck === null ||
                loggingEnabledE2ECheck === undefined) {
                throw new RequiredError("loggingEnabledE2ECheck", "Required parameter loggingEnabledE2ECheck was null or undefined when calling e2ECheck.");
            }
            const localVarPath = `/api/aimychat-be/e2e-check`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            if (zRequestId !== undefined && zRequestId !== null) {
                localVarHeaderParameter["Z-requestId"] = String(zRequestId);
            }
            if (loggingEnabledE2ECheck !== undefined &&
                loggingEnabledE2ECheck !== null) {
                localVarHeaderParameter["loggingEnabledE2ECheck"] = String(JSON.stringify(loggingEnabledE2ECheck));
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * E2ECheckApi - functional programming interface
 * @export
 */
export const E2ECheckApiFp = function (configuration) {
    return {
        /**
         * Method for E2ECheck.
         * @param {boolean} loggingEnabledE2ECheck enable/disable logging for e2e check
         * @param {string} [zRequestId] Request id header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        e2ECheck(loggingEnabledE2ECheck, zRequestId, options) {
            const localVarAxiosArgs = E2ECheckApiAxiosParamCreator(configuration).e2ECheck(loggingEnabledE2ECheck, zRequestId, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
    };
};
/**
 * E2ECheckApi - factory interface
 * @export
 */
export const E2ECheckApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         * Method for E2ECheck.
         * @param {boolean} loggingEnabledE2ECheck enable/disable logging for e2e check
         * @param {string} [zRequestId] Request id header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        e2ECheck(loggingEnabledE2ECheck, zRequestId, options) {
            return E2ECheckApiFp(configuration).e2ECheck(loggingEnabledE2ECheck, zRequestId, options)(axios, basePath);
        },
    };
};
/**
 * E2ECheckApi - object-oriented interface
 * @export
 * @class E2ECheckApi
 * @extends {BaseAPI}
 */
export class E2ECheckApi extends BaseAPI {
    /**
     * Method for E2ECheck.
     * @param {boolean} loggingEnabledE2ECheck enable/disable logging for e2e check
     * @param {string} [zRequestId] Request id header
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof E2ECheckApi
     */
    e2ECheck(loggingEnabledE2ECheck, zRequestId, options) {
        return E2ECheckApiFp(this.configuration).e2ECheck(loggingEnabledE2ECheck, zRequestId, options)(this.axios, this.basePath);
    }
}
/**
 * OperatorApi - axios parameter creator
 * @export
 */
export const OperatorApiAxiosParamCreator = function (configuration) {
    return {
        /**
         * Add operator to operator place
         * @param {number} accountId Account identifier
         * @param {AddOperatorDto} addOperatorDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addOperatorToOperatorPlace(accountId, addOperatorDto, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling addOperatorToOperatorPlace.");
            }
            // verify required parameter 'addOperatorDto' is not null or undefined
            if (addOperatorDto === null || addOperatorDto === undefined) {
                throw new RequiredError("addOperatorDto", "Required parameter addOperatorDto was null or undefined when calling addOperatorToOperatorPlace.");
            }
            const localVarPath = `/api/aimychat-be/accounts/{accountId}/operators/add-operator`.replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "POST" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarHeaderParameter["Content-Type"] = "application/json";
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof addOperatorDto !== "string" ||
                localVarRequestOptions.headers["Content-Type"] === "application/json";
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(addOperatorDto !== undefined ? addOperatorDto : {})
                : addOperatorDto || "";
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get operator
         * @param {number} accountId Account identifier
         * @param {number} operatorId Operator identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOperator(accountId, operatorId, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling getOperator.");
            }
            // verify required parameter 'operatorId' is not null or undefined
            if (operatorId === null || operatorId === undefined) {
                throw new RequiredError("operatorId", "Required parameter operatorId was null or undefined when calling getOperator.");
            }
            const localVarPath = `/api/aimychat-be/accounts/{accountId}/operators/{operatorId}`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"operatorId"}}`, encodeURIComponent(String(operatorId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Add operator to operator place
         * @param {number} accountId Account identifier
         * @param {number} ccUserId Conversational cloud user id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOperatorByCcUserId(accountId, ccUserId, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling getOperatorByCcUserId.");
            }
            // verify required parameter 'ccUserId' is not null or undefined
            if (ccUserId === null || ccUserId === undefined) {
                throw new RequiredError("ccUserId", "Required parameter ccUserId was null or undefined when calling getOperatorByCcUserId.");
            }
            const localVarPath = `/api/aimychat-be/accounts/{accountId}/operators/by-cc-user-id`.replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            if (ccUserId !== undefined) {
                localVarQueryParameter["ccUserId"] = ccUserId;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get operators
         * @param {number} accountId Account identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOperators(accountId, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling getOperators.");
            }
            const localVarPath = `/api/aimychat-be/accounts/{accountId}/operators`.replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Remove operator
         * @param {number} accountId Account identifier
         * @param {number} operatorId Operator identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeOperatorFromOperatorPlace(accountId, operatorId, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling removeOperatorFromOperatorPlace.");
            }
            // verify required parameter 'operatorId' is not null or undefined
            if (operatorId === null || operatorId === undefined) {
                throw new RequiredError("operatorId", "Required parameter operatorId was null or undefined when calling removeOperatorFromOperatorPlace.");
            }
            const localVarPath = `/api/aimychat-be/accounts/{accountId}/operators/{operatorId}`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"operatorId"}}`, encodeURIComponent(String(operatorId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "DELETE" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update operator
         * @param {number} accountId Account identifier
         * @param {number} operatorId Operator identifier
         * @param {UpdateOperatorDto} updateOperatorDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOperator(accountId, operatorId, updateOperatorDto, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling updateOperator.");
            }
            // verify required parameter 'operatorId' is not null or undefined
            if (operatorId === null || operatorId === undefined) {
                throw new RequiredError("operatorId", "Required parameter operatorId was null or undefined when calling updateOperator.");
            }
            // verify required parameter 'updateOperatorDto' is not null or undefined
            if (updateOperatorDto === null || updateOperatorDto === undefined) {
                throw new RequiredError("updateOperatorDto", "Required parameter updateOperatorDto was null or undefined when calling updateOperator.");
            }
            const localVarPath = `/api/aimychat-be/accounts/{accountId}/operators/{operatorId}`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"operatorId"}}`, encodeURIComponent(String(operatorId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "PUT" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarHeaderParameter["Content-Type"] = "application/json";
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof updateOperatorDto !== "string" ||
                localVarRequestOptions.headers["Content-Type"] === "application/json";
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(updateOperatorDto !== undefined ? updateOperatorDto : {})
                : updateOperatorDto || "";
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * OperatorApi - functional programming interface
 * @export
 */
export const OperatorApiFp = function (configuration) {
    return {
        /**
         * Add operator to operator place
         * @param {number} accountId Account identifier
         * @param {AddOperatorDto} addOperatorDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addOperatorToOperatorPlace(accountId, addOperatorDto, options) {
            const localVarAxiosArgs = OperatorApiAxiosParamCreator(configuration).addOperatorToOperatorPlace(accountId, addOperatorDto, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Get operator
         * @param {number} accountId Account identifier
         * @param {number} operatorId Operator identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOperator(accountId, operatorId, options) {
            const localVarAxiosArgs = OperatorApiAxiosParamCreator(configuration).getOperator(accountId, operatorId, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Add operator to operator place
         * @param {number} accountId Account identifier
         * @param {number} ccUserId Conversational cloud user id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOperatorByCcUserId(accountId, ccUserId, options) {
            const localVarAxiosArgs = OperatorApiAxiosParamCreator(configuration).getOperatorByCcUserId(accountId, ccUserId, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Get operators
         * @param {number} accountId Account identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOperators(accountId, options) {
            const localVarAxiosArgs = OperatorApiAxiosParamCreator(configuration).getOperators(accountId, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Remove operator
         * @param {number} accountId Account identifier
         * @param {number} operatorId Operator identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeOperatorFromOperatorPlace(accountId, operatorId, options) {
            const localVarAxiosArgs = OperatorApiAxiosParamCreator(configuration).removeOperatorFromOperatorPlace(accountId, operatorId, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Update operator
         * @param {number} accountId Account identifier
         * @param {number} operatorId Operator identifier
         * @param {UpdateOperatorDto} updateOperatorDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOperator(accountId, operatorId, updateOperatorDto, options) {
            const localVarAxiosArgs = OperatorApiAxiosParamCreator(configuration).updateOperator(accountId, operatorId, updateOperatorDto, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
    };
};
/**
 * OperatorApi - factory interface
 * @export
 */
export const OperatorApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         * Add operator to operator place
         * @param {number} accountId Account identifier
         * @param {AddOperatorDto} addOperatorDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addOperatorToOperatorPlace(accountId, addOperatorDto, options) {
            return OperatorApiFp(configuration).addOperatorToOperatorPlace(accountId, addOperatorDto, options)(axios, basePath);
        },
        /**
         * Get operator
         * @param {number} accountId Account identifier
         * @param {number} operatorId Operator identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOperator(accountId, operatorId, options) {
            return OperatorApiFp(configuration).getOperator(accountId, operatorId, options)(axios, basePath);
        },
        /**
         * Add operator to operator place
         * @param {number} accountId Account identifier
         * @param {number} ccUserId Conversational cloud user id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOperatorByCcUserId(accountId, ccUserId, options) {
            return OperatorApiFp(configuration).getOperatorByCcUserId(accountId, ccUserId, options)(axios, basePath);
        },
        /**
         * Get operators
         * @param {number} accountId Account identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOperators(accountId, options) {
            return OperatorApiFp(configuration).getOperators(accountId, options)(axios, basePath);
        },
        /**
         * Remove operator
         * @param {number} accountId Account identifier
         * @param {number} operatorId Operator identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeOperatorFromOperatorPlace(accountId, operatorId, options) {
            return OperatorApiFp(configuration).removeOperatorFromOperatorPlace(accountId, operatorId, options)(axios, basePath);
        },
        /**
         * Update operator
         * @param {number} accountId Account identifier
         * @param {number} operatorId Operator identifier
         * @param {UpdateOperatorDto} updateOperatorDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOperator(accountId, operatorId, updateOperatorDto, options) {
            return OperatorApiFp(configuration).updateOperator(accountId, operatorId, updateOperatorDto, options)(axios, basePath);
        },
    };
};
/**
 * OperatorApi - object-oriented interface
 * @export
 * @class OperatorApi
 * @extends {BaseAPI}
 */
export class OperatorApi extends BaseAPI {
    /**
     * Add operator to operator place
     * @param {number} accountId Account identifier
     * @param {AddOperatorDto} addOperatorDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OperatorApi
     */
    addOperatorToOperatorPlace(accountId, addOperatorDto, options) {
        return OperatorApiFp(this.configuration).addOperatorToOperatorPlace(accountId, addOperatorDto, options)(this.axios, this.basePath);
    }
    /**
     * Get operator
     * @param {number} accountId Account identifier
     * @param {number} operatorId Operator identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OperatorApi
     */
    getOperator(accountId, operatorId, options) {
        return OperatorApiFp(this.configuration).getOperator(accountId, operatorId, options)(this.axios, this.basePath);
    }
    /**
     * Add operator to operator place
     * @param {number} accountId Account identifier
     * @param {number} ccUserId Conversational cloud user id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OperatorApi
     */
    getOperatorByCcUserId(accountId, ccUserId, options) {
        return OperatorApiFp(this.configuration).getOperatorByCcUserId(accountId, ccUserId, options)(this.axios, this.basePath);
    }
    /**
     * Get operators
     * @param {number} accountId Account identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OperatorApi
     */
    getOperators(accountId, options) {
        return OperatorApiFp(this.configuration).getOperators(accountId, options)(this.axios, this.basePath);
    }
    /**
     * Remove operator
     * @param {number} accountId Account identifier
     * @param {number} operatorId Operator identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OperatorApi
     */
    removeOperatorFromOperatorPlace(accountId, operatorId, options) {
        return OperatorApiFp(this.configuration).removeOperatorFromOperatorPlace(accountId, operatorId, options)(this.axios, this.basePath);
    }
    /**
     * Update operator
     * @param {number} accountId Account identifier
     * @param {number} operatorId Operator identifier
     * @param {UpdateOperatorDto} updateOperatorDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OperatorApi
     */
    updateOperator(accountId, operatorId, updateOperatorDto, options) {
        return OperatorApiFp(this.configuration).updateOperator(accountId, operatorId, updateOperatorDto, options)(this.axios, this.basePath);
    }
}
/**
 * OperatorGroupApi - axios parameter creator
 * @export
 */
export const OperatorGroupApiAxiosParamCreator = function (configuration) {
    return {
        /**
         * Add operator to operator group
         * @param {number} accountId Account identifier
         * @param {number} operatorGroupId Operator group identifier
         * @param {OperatorIdDto} operatorIdDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addOperatorToGroup(accountId, operatorGroupId, operatorIdDto, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling addOperatorToGroup.");
            }
            // verify required parameter 'operatorGroupId' is not null or undefined
            if (operatorGroupId === null || operatorGroupId === undefined) {
                throw new RequiredError("operatorGroupId", "Required parameter operatorGroupId was null or undefined when calling addOperatorToGroup.");
            }
            // verify required parameter 'operatorIdDto' is not null or undefined
            if (operatorIdDto === null || operatorIdDto === undefined) {
                throw new RequiredError("operatorIdDto", "Required parameter operatorIdDto was null or undefined when calling addOperatorToGroup.");
            }
            const localVarPath = `/api/aimychat-be/accounts/{accountId}/operator-groups/{operatorGroupId}/add-operator`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"operatorGroupId"}}`, encodeURIComponent(String(operatorGroupId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "PUT" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarHeaderParameter["Content-Type"] = "application/json";
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof operatorIdDto !== "string" ||
                localVarRequestOptions.headers["Content-Type"] === "application/json";
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(operatorIdDto !== undefined ? operatorIdDto : {})
                : operatorIdDto || "";
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create operator group
         * @param {number} accountId Account identifier
         * @param {CreateOperatorGroupDto} createOperatorGroupDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOperatorGroup(accountId, createOperatorGroupDto, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling createOperatorGroup.");
            }
            // verify required parameter 'createOperatorGroupDto' is not null or undefined
            if (createOperatorGroupDto === null ||
                createOperatorGroupDto === undefined) {
                throw new RequiredError("createOperatorGroupDto", "Required parameter createOperatorGroupDto was null or undefined when calling createOperatorGroup.");
            }
            const localVarPath = `/api/aimychat-be/accounts/{accountId}/operator-groups`.replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "POST" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarHeaderParameter["Content-Type"] = "application/json";
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof createOperatorGroupDto !== "string" ||
                localVarRequestOptions.headers["Content-Type"] === "application/json";
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(createOperatorGroupDto !== undefined ? createOperatorGroupDto : {})
                : createOperatorGroupDto || "";
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get operator group
         * @param {number} accountId Account identifier
         * @param {number} operatorGroupId Operator group identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOperatorGroup(accountId, operatorGroupId, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling getOperatorGroup.");
            }
            // verify required parameter 'operatorGroupId' is not null or undefined
            if (operatorGroupId === null || operatorGroupId === undefined) {
                throw new RequiredError("operatorGroupId", "Required parameter operatorGroupId was null or undefined when calling getOperatorGroup.");
            }
            const localVarPath = `/api/aimychat-be/accounts/{accountId}/operator-groups/{operatorGroupId}`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"operatorGroupId"}}`, encodeURIComponent(String(operatorGroupId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get operator group by main operator
         * @param {number} accountId Account identifier
         * @param {string} mainOperator Main operator (id or login or email)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOperatorGroupByMainOperator(accountId, mainOperator, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling getOperatorGroupByMainOperator.");
            }
            // verify required parameter 'mainOperator' is not null or undefined
            if (mainOperator === null || mainOperator === undefined) {
                throw new RequiredError("mainOperator", "Required parameter mainOperator was null or undefined when calling getOperatorGroupByMainOperator.");
            }
            const localVarPath = `/api/aimychat-be/accounts/{accountId}/operator-groups/by-main-operator`.replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            if (mainOperator !== undefined) {
                localVarQueryParameter["mainOperator"] = mainOperator;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get operator groups
         * @param {number} accountId Account identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOperatorGroups(accountId, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling getOperatorGroups.");
            }
            const localVarPath = `/api/aimychat-be/accounts/{accountId}/operator-groups`.replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get operator groups
         * @param {string} token Operator place token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOperatorGroupsByToken(token, options = {}) {
            // verify required parameter 'token' is not null or undefined
            if (token === null || token === undefined) {
                throw new RequiredError("token", "Required parameter token was null or undefined when calling getOperatorGroupsByToken.");
            }
            const localVarPath = `/api/aimychat-be/tokens/{token}/operator-groups`.replace(`{${"token"}}`, encodeURIComponent(String(token)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Remove operator from operator group
         * @param {number} accountId Account identifier
         * @param {number} operatorGroupId Operator group identifier
         * @param {OperatorIdDto} operatorIdDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeOperatorFromGroup(accountId, operatorGroupId, operatorIdDto, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling removeOperatorFromGroup.");
            }
            // verify required parameter 'operatorGroupId' is not null or undefined
            if (operatorGroupId === null || operatorGroupId === undefined) {
                throw new RequiredError("operatorGroupId", "Required parameter operatorGroupId was null or undefined when calling removeOperatorFromGroup.");
            }
            // verify required parameter 'operatorIdDto' is not null or undefined
            if (operatorIdDto === null || operatorIdDto === undefined) {
                throw new RequiredError("operatorIdDto", "Required parameter operatorIdDto was null or undefined when calling removeOperatorFromGroup.");
            }
            const localVarPath = `/api/aimychat-be/accounts/{accountId}/operator-groups/{operatorGroupId}/remove-operator`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"operatorGroupId"}}`, encodeURIComponent(String(operatorGroupId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "PUT" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarHeaderParameter["Content-Type"] = "application/json";
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof operatorIdDto !== "string" ||
                localVarRequestOptions.headers["Content-Type"] === "application/json";
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(operatorIdDto !== undefined ? operatorIdDto : {})
                : operatorIdDto || "";
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Remove operator group
         * @param {number} accountId Account identifier
         * @param {number} operatorGroupId Operator group identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeOperatorGroup(accountId, operatorGroupId, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling removeOperatorGroup.");
            }
            // verify required parameter 'operatorGroupId' is not null or undefined
            if (operatorGroupId === null || operatorGroupId === undefined) {
                throw new RequiredError("operatorGroupId", "Required parameter operatorGroupId was null or undefined when calling removeOperatorGroup.");
            }
            const localVarPath = `/api/aimychat-be/accounts/{accountId}/operator-groups/{operatorGroupId}`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"operatorGroupId"}}`, encodeURIComponent(String(operatorGroupId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "DELETE" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update operator group
         * @param {number} accountId Account identifier
         * @param {number} operatorGroupId Operator group identifier
         * @param {UpdateOperatorGroupDto} updateOperatorGroupDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOperatorGroup(accountId, operatorGroupId, updateOperatorGroupDto, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling updateOperatorGroup.");
            }
            // verify required parameter 'operatorGroupId' is not null or undefined
            if (operatorGroupId === null || operatorGroupId === undefined) {
                throw new RequiredError("operatorGroupId", "Required parameter operatorGroupId was null or undefined when calling updateOperatorGroup.");
            }
            // verify required parameter 'updateOperatorGroupDto' is not null or undefined
            if (updateOperatorGroupDto === null ||
                updateOperatorGroupDto === undefined) {
                throw new RequiredError("updateOperatorGroupDto", "Required parameter updateOperatorGroupDto was null or undefined when calling updateOperatorGroup.");
            }
            const localVarPath = `/api/aimychat-be/accounts/{accountId}/operator-groups/{operatorGroupId}`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"operatorGroupId"}}`, encodeURIComponent(String(operatorGroupId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "PUT" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarHeaderParameter["Content-Type"] = "application/json";
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof updateOperatorGroupDto !== "string" ||
                localVarRequestOptions.headers["Content-Type"] === "application/json";
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(updateOperatorGroupDto !== undefined ? updateOperatorGroupDto : {})
                : updateOperatorGroupDto || "";
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * OperatorGroupApi - functional programming interface
 * @export
 */
export const OperatorGroupApiFp = function (configuration) {
    return {
        /**
         * Add operator to operator group
         * @param {number} accountId Account identifier
         * @param {number} operatorGroupId Operator group identifier
         * @param {OperatorIdDto} operatorIdDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addOperatorToGroup(accountId, operatorGroupId, operatorIdDto, options) {
            const localVarAxiosArgs = OperatorGroupApiAxiosParamCreator(configuration).addOperatorToGroup(accountId, operatorGroupId, operatorIdDto, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Create operator group
         * @param {number} accountId Account identifier
         * @param {CreateOperatorGroupDto} createOperatorGroupDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOperatorGroup(accountId, createOperatorGroupDto, options) {
            const localVarAxiosArgs = OperatorGroupApiAxiosParamCreator(configuration).createOperatorGroup(accountId, createOperatorGroupDto, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Get operator group
         * @param {number} accountId Account identifier
         * @param {number} operatorGroupId Operator group identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOperatorGroup(accountId, operatorGroupId, options) {
            const localVarAxiosArgs = OperatorGroupApiAxiosParamCreator(configuration).getOperatorGroup(accountId, operatorGroupId, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Get operator group by main operator
         * @param {number} accountId Account identifier
         * @param {string} mainOperator Main operator (id or login or email)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOperatorGroupByMainOperator(accountId, mainOperator, options) {
            const localVarAxiosArgs = OperatorGroupApiAxiosParamCreator(configuration).getOperatorGroupByMainOperator(accountId, mainOperator, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Get operator groups
         * @param {number} accountId Account identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOperatorGroups(accountId, options) {
            const localVarAxiosArgs = OperatorGroupApiAxiosParamCreator(configuration).getOperatorGroups(accountId, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Get operator groups
         * @param {string} token Operator place token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOperatorGroupsByToken(token, options) {
            const localVarAxiosArgs = OperatorGroupApiAxiosParamCreator(configuration).getOperatorGroupsByToken(token, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Remove operator from operator group
         * @param {number} accountId Account identifier
         * @param {number} operatorGroupId Operator group identifier
         * @param {OperatorIdDto} operatorIdDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeOperatorFromGroup(accountId, operatorGroupId, operatorIdDto, options) {
            const localVarAxiosArgs = OperatorGroupApiAxiosParamCreator(configuration).removeOperatorFromGroup(accountId, operatorGroupId, operatorIdDto, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Remove operator group
         * @param {number} accountId Account identifier
         * @param {number} operatorGroupId Operator group identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeOperatorGroup(accountId, operatorGroupId, options) {
            const localVarAxiosArgs = OperatorGroupApiAxiosParamCreator(configuration).removeOperatorGroup(accountId, operatorGroupId, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Update operator group
         * @param {number} accountId Account identifier
         * @param {number} operatorGroupId Operator group identifier
         * @param {UpdateOperatorGroupDto} updateOperatorGroupDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOperatorGroup(accountId, operatorGroupId, updateOperatorGroupDto, options) {
            const localVarAxiosArgs = OperatorGroupApiAxiosParamCreator(configuration).updateOperatorGroup(accountId, operatorGroupId, updateOperatorGroupDto, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
    };
};
/**
 * OperatorGroupApi - factory interface
 * @export
 */
export const OperatorGroupApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         * Add operator to operator group
         * @param {number} accountId Account identifier
         * @param {number} operatorGroupId Operator group identifier
         * @param {OperatorIdDto} operatorIdDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addOperatorToGroup(accountId, operatorGroupId, operatorIdDto, options) {
            return OperatorGroupApiFp(configuration).addOperatorToGroup(accountId, operatorGroupId, operatorIdDto, options)(axios, basePath);
        },
        /**
         * Create operator group
         * @param {number} accountId Account identifier
         * @param {CreateOperatorGroupDto} createOperatorGroupDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOperatorGroup(accountId, createOperatorGroupDto, options) {
            return OperatorGroupApiFp(configuration).createOperatorGroup(accountId, createOperatorGroupDto, options)(axios, basePath);
        },
        /**
         * Get operator group
         * @param {number} accountId Account identifier
         * @param {number} operatorGroupId Operator group identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOperatorGroup(accountId, operatorGroupId, options) {
            return OperatorGroupApiFp(configuration).getOperatorGroup(accountId, operatorGroupId, options)(axios, basePath);
        },
        /**
         * Get operator group by main operator
         * @param {number} accountId Account identifier
         * @param {string} mainOperator Main operator (id or login or email)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOperatorGroupByMainOperator(accountId, mainOperator, options) {
            return OperatorGroupApiFp(configuration).getOperatorGroupByMainOperator(accountId, mainOperator, options)(axios, basePath);
        },
        /**
         * Get operator groups
         * @param {number} accountId Account identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOperatorGroups(accountId, options) {
            return OperatorGroupApiFp(configuration).getOperatorGroups(accountId, options)(axios, basePath);
        },
        /**
         * Get operator groups
         * @param {string} token Operator place token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOperatorGroupsByToken(token, options) {
            return OperatorGroupApiFp(configuration).getOperatorGroupsByToken(token, options)(axios, basePath);
        },
        /**
         * Remove operator from operator group
         * @param {number} accountId Account identifier
         * @param {number} operatorGroupId Operator group identifier
         * @param {OperatorIdDto} operatorIdDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeOperatorFromGroup(accountId, operatorGroupId, operatorIdDto, options) {
            return OperatorGroupApiFp(configuration).removeOperatorFromGroup(accountId, operatorGroupId, operatorIdDto, options)(axios, basePath);
        },
        /**
         * Remove operator group
         * @param {number} accountId Account identifier
         * @param {number} operatorGroupId Operator group identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeOperatorGroup(accountId, operatorGroupId, options) {
            return OperatorGroupApiFp(configuration).removeOperatorGroup(accountId, operatorGroupId, options)(axios, basePath);
        },
        /**
         * Update operator group
         * @param {number} accountId Account identifier
         * @param {number} operatorGroupId Operator group identifier
         * @param {UpdateOperatorGroupDto} updateOperatorGroupDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOperatorGroup(accountId, operatorGroupId, updateOperatorGroupDto, options) {
            return OperatorGroupApiFp(configuration).updateOperatorGroup(accountId, operatorGroupId, updateOperatorGroupDto, options)(axios, basePath);
        },
    };
};
/**
 * OperatorGroupApi - object-oriented interface
 * @export
 * @class OperatorGroupApi
 * @extends {BaseAPI}
 */
export class OperatorGroupApi extends BaseAPI {
    /**
     * Add operator to operator group
     * @param {number} accountId Account identifier
     * @param {number} operatorGroupId Operator group identifier
     * @param {OperatorIdDto} operatorIdDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OperatorGroupApi
     */
    addOperatorToGroup(accountId, operatorGroupId, operatorIdDto, options) {
        return OperatorGroupApiFp(this.configuration).addOperatorToGroup(accountId, operatorGroupId, operatorIdDto, options)(this.axios, this.basePath);
    }
    /**
     * Create operator group
     * @param {number} accountId Account identifier
     * @param {CreateOperatorGroupDto} createOperatorGroupDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OperatorGroupApi
     */
    createOperatorGroup(accountId, createOperatorGroupDto, options) {
        return OperatorGroupApiFp(this.configuration).createOperatorGroup(accountId, createOperatorGroupDto, options)(this.axios, this.basePath);
    }
    /**
     * Get operator group
     * @param {number} accountId Account identifier
     * @param {number} operatorGroupId Operator group identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OperatorGroupApi
     */
    getOperatorGroup(accountId, operatorGroupId, options) {
        return OperatorGroupApiFp(this.configuration).getOperatorGroup(accountId, operatorGroupId, options)(this.axios, this.basePath);
    }
    /**
     * Get operator group by main operator
     * @param {number} accountId Account identifier
     * @param {string} mainOperator Main operator (id or login or email)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OperatorGroupApi
     */
    getOperatorGroupByMainOperator(accountId, mainOperator, options) {
        return OperatorGroupApiFp(this.configuration).getOperatorGroupByMainOperator(accountId, mainOperator, options)(this.axios, this.basePath);
    }
    /**
     * Get operator groups
     * @param {number} accountId Account identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OperatorGroupApi
     */
    getOperatorGroups(accountId, options) {
        return OperatorGroupApiFp(this.configuration).getOperatorGroups(accountId, options)(this.axios, this.basePath);
    }
    /**
     * Get operator groups
     * @param {string} token Operator place token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OperatorGroupApi
     */
    getOperatorGroupsByToken(token, options) {
        return OperatorGroupApiFp(this.configuration).getOperatorGroupsByToken(token, options)(this.axios, this.basePath);
    }
    /**
     * Remove operator from operator group
     * @param {number} accountId Account identifier
     * @param {number} operatorGroupId Operator group identifier
     * @param {OperatorIdDto} operatorIdDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OperatorGroupApi
     */
    removeOperatorFromGroup(accountId, operatorGroupId, operatorIdDto, options) {
        return OperatorGroupApiFp(this.configuration).removeOperatorFromGroup(accountId, operatorGroupId, operatorIdDto, options)(this.axios, this.basePath);
    }
    /**
     * Remove operator group
     * @param {number} accountId Account identifier
     * @param {number} operatorGroupId Operator group identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OperatorGroupApi
     */
    removeOperatorGroup(accountId, operatorGroupId, options) {
        return OperatorGroupApiFp(this.configuration).removeOperatorGroup(accountId, operatorGroupId, options)(this.axios, this.basePath);
    }
    /**
     * Update operator group
     * @param {number} accountId Account identifier
     * @param {number} operatorGroupId Operator group identifier
     * @param {UpdateOperatorGroupDto} updateOperatorGroupDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OperatorGroupApi
     */
    updateOperatorGroup(accountId, operatorGroupId, updateOperatorGroupDto, options) {
        return OperatorGroupApiFp(this.configuration).updateOperatorGroup(accountId, operatorGroupId, updateOperatorGroupDto, options)(this.axios, this.basePath);
    }
}
/**
 * OperatorPlaceApi - axios parameter creator
 * @export
 */
export const OperatorPlaceApiAxiosParamCreator = function (configuration) {
    return {
        /**
         * Get operator place configuration
         * @param {number} accountId Account identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOperatorPlace(accountId, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling getOperatorPlace.");
            }
            const localVarPath = `/api/aimychat-be/accounts/{accountId}/operator-place`.replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update operator place configuration
         * @param {number} accountId Account identifier
         * @param {UpdateOperatorPlaceDto} updateOperatorPlaceDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOperatorPlace(accountId, updateOperatorPlaceDto, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling updateOperatorPlace.");
            }
            // verify required parameter 'updateOperatorPlaceDto' is not null or undefined
            if (updateOperatorPlaceDto === null ||
                updateOperatorPlaceDto === undefined) {
                throw new RequiredError("updateOperatorPlaceDto", "Required parameter updateOperatorPlaceDto was null or undefined when calling updateOperatorPlace.");
            }
            const localVarPath = `/api/aimychat-be/accounts/{accountId}/operator-place`.replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "PUT" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarHeaderParameter["Content-Type"] = "application/json";
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof updateOperatorPlaceDto !== "string" ||
                localVarRequestOptions.headers["Content-Type"] === "application/json";
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(updateOperatorPlaceDto !== undefined ? updateOperatorPlaceDto : {})
                : updateOperatorPlaceDto || "";
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update operator place token
         * @param {number} accountId Account identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOperatorPlaceToken(accountId, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling updateOperatorPlaceToken.");
            }
            const localVarPath = `/api/aimychat-be/accounts/{accountId}/operator-place/token`.replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "PATCH" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * OperatorPlaceApi - functional programming interface
 * @export
 */
export const OperatorPlaceApiFp = function (configuration) {
    return {
        /**
         * Get operator place configuration
         * @param {number} accountId Account identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOperatorPlace(accountId, options) {
            const localVarAxiosArgs = OperatorPlaceApiAxiosParamCreator(configuration).getOperatorPlace(accountId, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Update operator place configuration
         * @param {number} accountId Account identifier
         * @param {UpdateOperatorPlaceDto} updateOperatorPlaceDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOperatorPlace(accountId, updateOperatorPlaceDto, options) {
            const localVarAxiosArgs = OperatorPlaceApiAxiosParamCreator(configuration).updateOperatorPlace(accountId, updateOperatorPlaceDto, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Update operator place token
         * @param {number} accountId Account identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOperatorPlaceToken(accountId, options) {
            const localVarAxiosArgs = OperatorPlaceApiAxiosParamCreator(configuration).updateOperatorPlaceToken(accountId, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
    };
};
/**
 * OperatorPlaceApi - factory interface
 * @export
 */
export const OperatorPlaceApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         * Get operator place configuration
         * @param {number} accountId Account identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOperatorPlace(accountId, options) {
            return OperatorPlaceApiFp(configuration).getOperatorPlace(accountId, options)(axios, basePath);
        },
        /**
         * Update operator place configuration
         * @param {number} accountId Account identifier
         * @param {UpdateOperatorPlaceDto} updateOperatorPlaceDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOperatorPlace(accountId, updateOperatorPlaceDto, options) {
            return OperatorPlaceApiFp(configuration).updateOperatorPlace(accountId, updateOperatorPlaceDto, options)(axios, basePath);
        },
        /**
         * Update operator place token
         * @param {number} accountId Account identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOperatorPlaceToken(accountId, options) {
            return OperatorPlaceApiFp(configuration).updateOperatorPlaceToken(accountId, options)(axios, basePath);
        },
    };
};
/**
 * OperatorPlaceApi - object-oriented interface
 * @export
 * @class OperatorPlaceApi
 * @extends {BaseAPI}
 */
export class OperatorPlaceApi extends BaseAPI {
    /**
     * Get operator place configuration
     * @param {number} accountId Account identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OperatorPlaceApi
     */
    getOperatorPlace(accountId, options) {
        return OperatorPlaceApiFp(this.configuration).getOperatorPlace(accountId, options)(this.axios, this.basePath);
    }
    /**
     * Update operator place configuration
     * @param {number} accountId Account identifier
     * @param {UpdateOperatorPlaceDto} updateOperatorPlaceDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OperatorPlaceApi
     */
    updateOperatorPlace(accountId, updateOperatorPlaceDto, options) {
        return OperatorPlaceApiFp(this.configuration).updateOperatorPlace(accountId, updateOperatorPlaceDto, options)(this.axios, this.basePath);
    }
    /**
     * Update operator place token
     * @param {number} accountId Account identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OperatorPlaceApi
     */
    updateOperatorPlaceToken(accountId, options) {
        return OperatorPlaceApiFp(this.configuration).updateOperatorPlaceToken(accountId, options)(this.axios, this.basePath);
    }
}
/**
 * OperatorPlaceIFrameApi - axios parameter creator
 * @export
 */
export const OperatorPlaceIFrameApiAxiosParamCreator = function (configuration) {
    return {
        /**
         * Create iframe
         * @param {number} accountId Account identifier
         * @param {IFrameCreateDto} iFrameCreateDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOperatorPlaceIFrame(accountId, iFrameCreateDto, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling createOperatorPlaceIFrame.");
            }
            // verify required parameter 'iFrameCreateDto' is not null or undefined
            if (iFrameCreateDto === null || iFrameCreateDto === undefined) {
                throw new RequiredError("iFrameCreateDto", "Required parameter iFrameCreateDto was null or undefined when calling createOperatorPlaceIFrame.");
            }
            const localVarPath = `/api/aimychat-be/accounts/{accountId}/iframes`.replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "POST" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarHeaderParameter["Content-Type"] = "application/json";
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof iFrameCreateDto !== "string" ||
                localVarRequestOptions.headers["Content-Type"] === "application/json";
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(iFrameCreateDto !== undefined ? iFrameCreateDto : {})
                : iFrameCreateDto || "";
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete iframe
         * @param {number} accountId Account identifier
         * @param {IFrameDeleteDto} iFrameDeleteDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOperatorPlaceIFrame(accountId, iFrameDeleteDto, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling deleteOperatorPlaceIFrame.");
            }
            // verify required parameter 'iFrameDeleteDto' is not null or undefined
            if (iFrameDeleteDto === null || iFrameDeleteDto === undefined) {
                throw new RequiredError("iFrameDeleteDto", "Required parameter iFrameDeleteDto was null or undefined when calling deleteOperatorPlaceIFrame.");
            }
            const localVarPath = `/api/aimychat-be/accounts/{accountId}/iframes`.replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "DELETE" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarHeaderParameter["Content-Type"] = "application/json";
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof iFrameDeleteDto !== "string" ||
                localVarRequestOptions.headers["Content-Type"] === "application/json";
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(iFrameDeleteDto !== undefined ? iFrameDeleteDto : {})
                : iFrameDeleteDto || "";
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get enabled/disabled iframe for operator group
         * @param {number} accountId Account identifier
         * @param {number} operatorGroupId Operator group identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOperatorGroupIFrames(accountId, operatorGroupId, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling getOperatorGroupIFrames.");
            }
            // verify required parameter 'operatorGroupId' is not null or undefined
            if (operatorGroupId === null || operatorGroupId === undefined) {
                throw new RequiredError("operatorGroupId", "Required parameter operatorGroupId was null or undefined when calling getOperatorGroupIFrames.");
            }
            const localVarPath = `/api/aimychat-be/accounts/{accountId}/operator-groups/{operatorGroupId}/iframes`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"operatorGroupId"}}`, encodeURIComponent(String(operatorGroupId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get enabled iframes
         * @param {number} accountId Account identifier
         * @param {number} operatorId Operator identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOperatorIFrames(accountId, operatorId, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling getOperatorIFrames.");
            }
            // verify required parameter 'operatorId' is not null or undefined
            if (operatorId === null || operatorId === undefined) {
                throw new RequiredError("operatorId", "Required parameter operatorId was null or undefined when calling getOperatorIFrames.");
            }
            const localVarPath = `/api/aimychat-be/accounts/{accountId}/operator/{operatorId}/iframes`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"operatorId"}}`, encodeURIComponent(String(operatorId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get operator group iframes
         * @param {number} accountId Account identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOperatorPlaceIFrames(accountId, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling getOperatorPlaceIFrames.");
            }
            const localVarPath = `/api/aimychat-be/accounts/{accountId}/iframes`.replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Enable/disable iframe for operator group
         * @param {number} accountId Account identifier
         * @param {number} operatorGroupId Operator group identifier
         * @param {UpdateOperatorGroupIFramesDto} updateOperatorGroupIFramesDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOperatorGroupIFrame(accountId, operatorGroupId, updateOperatorGroupIFramesDto, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling updateOperatorGroupIFrame.");
            }
            // verify required parameter 'operatorGroupId' is not null or undefined
            if (operatorGroupId === null || operatorGroupId === undefined) {
                throw new RequiredError("operatorGroupId", "Required parameter operatorGroupId was null or undefined when calling updateOperatorGroupIFrame.");
            }
            // verify required parameter 'updateOperatorGroupIFramesDto' is not null or undefined
            if (updateOperatorGroupIFramesDto === null ||
                updateOperatorGroupIFramesDto === undefined) {
                throw new RequiredError("updateOperatorGroupIFramesDto", "Required parameter updateOperatorGroupIFramesDto was null or undefined when calling updateOperatorGroupIFrame.");
            }
            const localVarPath = `/api/aimychat-be/accounts/{accountId}/operator-groups/{operatorGroupId}/iframes`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"operatorGroupId"}}`, encodeURIComponent(String(operatorGroupId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "PUT" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarHeaderParameter["Content-Type"] = "application/json";
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof updateOperatorGroupIFramesDto !== "string" ||
                localVarRequestOptions.headers["Content-Type"] === "application/json";
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(updateOperatorGroupIFramesDto !== undefined
                    ? updateOperatorGroupIFramesDto
                    : {})
                : updateOperatorGroupIFramesDto || "";
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update iframe
         * @param {number} accountId Account identifier
         * @param {IFrameUpdateDto} iFrameUpdateDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOperatorPlaceIFrame(accountId, iFrameUpdateDto, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling updateOperatorPlaceIFrame.");
            }
            // verify required parameter 'iFrameUpdateDto' is not null or undefined
            if (iFrameUpdateDto === null || iFrameUpdateDto === undefined) {
                throw new RequiredError("iFrameUpdateDto", "Required parameter iFrameUpdateDto was null or undefined when calling updateOperatorPlaceIFrame.");
            }
            const localVarPath = `/api/aimychat-be/accounts/{accountId}/iframes`.replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "PUT" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarHeaderParameter["Content-Type"] = "application/json";
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof iFrameUpdateDto !== "string" ||
                localVarRequestOptions.headers["Content-Type"] === "application/json";
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(iFrameUpdateDto !== undefined ? iFrameUpdateDto : {})
                : iFrameUpdateDto || "";
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * OperatorPlaceIFrameApi - functional programming interface
 * @export
 */
export const OperatorPlaceIFrameApiFp = function (configuration) {
    return {
        /**
         * Create iframe
         * @param {number} accountId Account identifier
         * @param {IFrameCreateDto} iFrameCreateDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOperatorPlaceIFrame(accountId, iFrameCreateDto, options) {
            const localVarAxiosArgs = OperatorPlaceIFrameApiAxiosParamCreator(configuration).createOperatorPlaceIFrame(accountId, iFrameCreateDto, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Delete iframe
         * @param {number} accountId Account identifier
         * @param {IFrameDeleteDto} iFrameDeleteDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOperatorPlaceIFrame(accountId, iFrameDeleteDto, options) {
            const localVarAxiosArgs = OperatorPlaceIFrameApiAxiosParamCreator(configuration).deleteOperatorPlaceIFrame(accountId, iFrameDeleteDto, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Get enabled/disabled iframe for operator group
         * @param {number} accountId Account identifier
         * @param {number} operatorGroupId Operator group identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOperatorGroupIFrames(accountId, operatorGroupId, options) {
            const localVarAxiosArgs = OperatorPlaceIFrameApiAxiosParamCreator(configuration).getOperatorGroupIFrames(accountId, operatorGroupId, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Get enabled iframes
         * @param {number} accountId Account identifier
         * @param {number} operatorId Operator identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOperatorIFrames(accountId, operatorId, options) {
            const localVarAxiosArgs = OperatorPlaceIFrameApiAxiosParamCreator(configuration).getOperatorIFrames(accountId, operatorId, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Get operator group iframes
         * @param {number} accountId Account identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOperatorPlaceIFrames(accountId, options) {
            const localVarAxiosArgs = OperatorPlaceIFrameApiAxiosParamCreator(configuration).getOperatorPlaceIFrames(accountId, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Enable/disable iframe for operator group
         * @param {number} accountId Account identifier
         * @param {number} operatorGroupId Operator group identifier
         * @param {UpdateOperatorGroupIFramesDto} updateOperatorGroupIFramesDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOperatorGroupIFrame(accountId, operatorGroupId, updateOperatorGroupIFramesDto, options) {
            const localVarAxiosArgs = OperatorPlaceIFrameApiAxiosParamCreator(configuration).updateOperatorGroupIFrame(accountId, operatorGroupId, updateOperatorGroupIFramesDto, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Update iframe
         * @param {number} accountId Account identifier
         * @param {IFrameUpdateDto} iFrameUpdateDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOperatorPlaceIFrame(accountId, iFrameUpdateDto, options) {
            const localVarAxiosArgs = OperatorPlaceIFrameApiAxiosParamCreator(configuration).updateOperatorPlaceIFrame(accountId, iFrameUpdateDto, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
    };
};
/**
 * OperatorPlaceIFrameApi - factory interface
 * @export
 */
export const OperatorPlaceIFrameApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         * Create iframe
         * @param {number} accountId Account identifier
         * @param {IFrameCreateDto} iFrameCreateDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOperatorPlaceIFrame(accountId, iFrameCreateDto, options) {
            return OperatorPlaceIFrameApiFp(configuration).createOperatorPlaceIFrame(accountId, iFrameCreateDto, options)(axios, basePath);
        },
        /**
         * Delete iframe
         * @param {number} accountId Account identifier
         * @param {IFrameDeleteDto} iFrameDeleteDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOperatorPlaceIFrame(accountId, iFrameDeleteDto, options) {
            return OperatorPlaceIFrameApiFp(configuration).deleteOperatorPlaceIFrame(accountId, iFrameDeleteDto, options)(axios, basePath);
        },
        /**
         * Get enabled/disabled iframe for operator group
         * @param {number} accountId Account identifier
         * @param {number} operatorGroupId Operator group identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOperatorGroupIFrames(accountId, operatorGroupId, options) {
            return OperatorPlaceIFrameApiFp(configuration).getOperatorGroupIFrames(accountId, operatorGroupId, options)(axios, basePath);
        },
        /**
         * Get enabled iframes
         * @param {number} accountId Account identifier
         * @param {number} operatorId Operator identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOperatorIFrames(accountId, operatorId, options) {
            return OperatorPlaceIFrameApiFp(configuration).getOperatorIFrames(accountId, operatorId, options)(axios, basePath);
        },
        /**
         * Get operator group iframes
         * @param {number} accountId Account identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOperatorPlaceIFrames(accountId, options) {
            return OperatorPlaceIFrameApiFp(configuration).getOperatorPlaceIFrames(accountId, options)(axios, basePath);
        },
        /**
         * Enable/disable iframe for operator group
         * @param {number} accountId Account identifier
         * @param {number} operatorGroupId Operator group identifier
         * @param {UpdateOperatorGroupIFramesDto} updateOperatorGroupIFramesDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOperatorGroupIFrame(accountId, operatorGroupId, updateOperatorGroupIFramesDto, options) {
            return OperatorPlaceIFrameApiFp(configuration).updateOperatorGroupIFrame(accountId, operatorGroupId, updateOperatorGroupIFramesDto, options)(axios, basePath);
        },
        /**
         * Update iframe
         * @param {number} accountId Account identifier
         * @param {IFrameUpdateDto} iFrameUpdateDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOperatorPlaceIFrame(accountId, iFrameUpdateDto, options) {
            return OperatorPlaceIFrameApiFp(configuration).updateOperatorPlaceIFrame(accountId, iFrameUpdateDto, options)(axios, basePath);
        },
    };
};
/**
 * OperatorPlaceIFrameApi - object-oriented interface
 * @export
 * @class OperatorPlaceIFrameApi
 * @extends {BaseAPI}
 */
export class OperatorPlaceIFrameApi extends BaseAPI {
    /**
     * Create iframe
     * @param {number} accountId Account identifier
     * @param {IFrameCreateDto} iFrameCreateDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OperatorPlaceIFrameApi
     */
    createOperatorPlaceIFrame(accountId, iFrameCreateDto, options) {
        return OperatorPlaceIFrameApiFp(this.configuration).createOperatorPlaceIFrame(accountId, iFrameCreateDto, options)(this.axios, this.basePath);
    }
    /**
     * Delete iframe
     * @param {number} accountId Account identifier
     * @param {IFrameDeleteDto} iFrameDeleteDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OperatorPlaceIFrameApi
     */
    deleteOperatorPlaceIFrame(accountId, iFrameDeleteDto, options) {
        return OperatorPlaceIFrameApiFp(this.configuration).deleteOperatorPlaceIFrame(accountId, iFrameDeleteDto, options)(this.axios, this.basePath);
    }
    /**
     * Get enabled/disabled iframe for operator group
     * @param {number} accountId Account identifier
     * @param {number} operatorGroupId Operator group identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OperatorPlaceIFrameApi
     */
    getOperatorGroupIFrames(accountId, operatorGroupId, options) {
        return OperatorPlaceIFrameApiFp(this.configuration).getOperatorGroupIFrames(accountId, operatorGroupId, options)(this.axios, this.basePath);
    }
    /**
     * Get enabled iframes
     * @param {number} accountId Account identifier
     * @param {number} operatorId Operator identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OperatorPlaceIFrameApi
     */
    getOperatorIFrames(accountId, operatorId, options) {
        return OperatorPlaceIFrameApiFp(this.configuration).getOperatorIFrames(accountId, operatorId, options)(this.axios, this.basePath);
    }
    /**
     * Get operator group iframes
     * @param {number} accountId Account identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OperatorPlaceIFrameApi
     */
    getOperatorPlaceIFrames(accountId, options) {
        return OperatorPlaceIFrameApiFp(this.configuration).getOperatorPlaceIFrames(accountId, options)(this.axios, this.basePath);
    }
    /**
     * Enable/disable iframe for operator group
     * @param {number} accountId Account identifier
     * @param {number} operatorGroupId Operator group identifier
     * @param {UpdateOperatorGroupIFramesDto} updateOperatorGroupIFramesDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OperatorPlaceIFrameApi
     */
    updateOperatorGroupIFrame(accountId, operatorGroupId, updateOperatorGroupIFramesDto, options) {
        return OperatorPlaceIFrameApiFp(this.configuration).updateOperatorGroupIFrame(accountId, operatorGroupId, updateOperatorGroupIFramesDto, options)(this.axios, this.basePath);
    }
    /**
     * Update iframe
     * @param {number} accountId Account identifier
     * @param {IFrameUpdateDto} iFrameUpdateDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OperatorPlaceIFrameApi
     */
    updateOperatorPlaceIFrame(accountId, iFrameUpdateDto, options) {
        return OperatorPlaceIFrameApiFp(this.configuration).updateOperatorPlaceIFrame(accountId, iFrameUpdateDto, options)(this.axios, this.basePath);
    }
}
/**
 * ProductInfoApi - axios parameter creator
 * @export
 */
export const ProductInfoApiAxiosParamCreator = function (configuration) {
    return {
        /**
         * Get product information
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductInfo(options = {}) {
            const localVarPath = `/api/aimychat-be/product-info`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * ProductInfoApi - functional programming interface
 * @export
 */
export const ProductInfoApiFp = function (configuration) {
    return {
        /**
         * Get product information
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductInfo(options) {
            const localVarAxiosArgs = ProductInfoApiAxiosParamCreator(configuration).getProductInfo(options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
    };
};
/**
 * ProductInfoApi - factory interface
 * @export
 */
export const ProductInfoApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         * Get product information
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductInfo(options) {
            return ProductInfoApiFp(configuration).getProductInfo(options)(axios, basePath);
        },
    };
};
/**
 * ProductInfoApi - object-oriented interface
 * @export
 * @class ProductInfoApi
 * @extends {BaseAPI}
 */
export class ProductInfoApi extends BaseAPI {
    /**
     * Get product information
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductInfoApi
     */
    getProductInfo(options) {
        return ProductInfoApiFp(this.configuration).getProductInfo(options)(this.axios, this.basePath);
    }
}
/**
 * PrompterApi - axios parameter creator
 * @export
 */
export const PrompterApiAxiosParamCreator = function (configuration) {
    return {
        /**
         * Create prompter
         * @param {number} accountId Account identifier
         * @param {CreatePrompterDto} createPrompterDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPrompter(accountId, createPrompterDto, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling createPrompter.");
            }
            // verify required parameter 'createPrompterDto' is not null or undefined
            if (createPrompterDto === null || createPrompterDto === undefined) {
                throw new RequiredError("createPrompterDto", "Required parameter createPrompterDto was null or undefined when calling createPrompter.");
            }
            const localVarPath = `/api/aimychat-be/accounts/{accountId}/prompters`.replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "POST" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarHeaderParameter["Content-Type"] = "application/json";
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof createPrompterDto !== "string" ||
                localVarRequestOptions.headers["Content-Type"] === "application/json";
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(createPrompterDto !== undefined ? createPrompterDto : {})
                : createPrompterDto || "";
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get prompter
         * @param {number} accountId Account identifier
         * @param {number} prompterId Prompter identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPrompter(accountId, prompterId, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling getPrompter.");
            }
            // verify required parameter 'prompterId' is not null or undefined
            if (prompterId === null || prompterId === undefined) {
                throw new RequiredError("prompterId", "Required parameter prompterId was null or undefined when calling getPrompter.");
            }
            const localVarPath = `/api/aimychat-be/accounts/{accountId}/prompters/{prompterId}`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"prompterId"}}`, encodeURIComponent(String(prompterId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get prompters
         * @param {number} accountId Account identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPrompters(accountId, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling getPrompters.");
            }
            const localVarPath = `/api/aimychat-be/accounts/{accountId}/prompters`.replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Remove prompter
         * @param {number} accountId Account identifier
         * @param {number} prompterId Prompter identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removePrompter(accountId, prompterId, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling removePrompter.");
            }
            // verify required parameter 'prompterId' is not null or undefined
            if (prompterId === null || prompterId === undefined) {
                throw new RequiredError("prompterId", "Required parameter prompterId was null or undefined when calling removePrompter.");
            }
            const localVarPath = `/api/aimychat-be/accounts/{accountId}/prompters/{prompterId}`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"prompterId"}}`, encodeURIComponent(String(prompterId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "DELETE" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update prompter
         * @param {number} accountId Account identifier
         * @param {number} prompterId Prompter identifier
         * @param {UpdatePrompterDto} updatePrompterDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePrompter(accountId, prompterId, updatePrompterDto, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling updatePrompter.");
            }
            // verify required parameter 'prompterId' is not null or undefined
            if (prompterId === null || prompterId === undefined) {
                throw new RequiredError("prompterId", "Required parameter prompterId was null or undefined when calling updatePrompter.");
            }
            // verify required parameter 'updatePrompterDto' is not null or undefined
            if (updatePrompterDto === null || updatePrompterDto === undefined) {
                throw new RequiredError("updatePrompterDto", "Required parameter updatePrompterDto was null or undefined when calling updatePrompter.");
            }
            const localVarPath = `/api/aimychat-be/accounts/{accountId}/prompters/{prompterId}`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"prompterId"}}`, encodeURIComponent(String(prompterId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "PUT" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarHeaderParameter["Content-Type"] = "application/json";
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof updatePrompterDto !== "string" ||
                localVarRequestOptions.headers["Content-Type"] === "application/json";
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(updatePrompterDto !== undefined ? updatePrompterDto : {})
                : updatePrompterDto || "";
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * PrompterApi - functional programming interface
 * @export
 */
export const PrompterApiFp = function (configuration) {
    return {
        /**
         * Create prompter
         * @param {number} accountId Account identifier
         * @param {CreatePrompterDto} createPrompterDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPrompter(accountId, createPrompterDto, options) {
            const localVarAxiosArgs = PrompterApiAxiosParamCreator(configuration).createPrompter(accountId, createPrompterDto, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Get prompter
         * @param {number} accountId Account identifier
         * @param {number} prompterId Prompter identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPrompter(accountId, prompterId, options) {
            const localVarAxiosArgs = PrompterApiAxiosParamCreator(configuration).getPrompter(accountId, prompterId, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Get prompters
         * @param {number} accountId Account identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPrompters(accountId, options) {
            const localVarAxiosArgs = PrompterApiAxiosParamCreator(configuration).getPrompters(accountId, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Remove prompter
         * @param {number} accountId Account identifier
         * @param {number} prompterId Prompter identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removePrompter(accountId, prompterId, options) {
            const localVarAxiosArgs = PrompterApiAxiosParamCreator(configuration).removePrompter(accountId, prompterId, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Update prompter
         * @param {number} accountId Account identifier
         * @param {number} prompterId Prompter identifier
         * @param {UpdatePrompterDto} updatePrompterDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePrompter(accountId, prompterId, updatePrompterDto, options) {
            const localVarAxiosArgs = PrompterApiAxiosParamCreator(configuration).updatePrompter(accountId, prompterId, updatePrompterDto, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
    };
};
/**
 * PrompterApi - factory interface
 * @export
 */
export const PrompterApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         * Create prompter
         * @param {number} accountId Account identifier
         * @param {CreatePrompterDto} createPrompterDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPrompter(accountId, createPrompterDto, options) {
            return PrompterApiFp(configuration).createPrompter(accountId, createPrompterDto, options)(axios, basePath);
        },
        /**
         * Get prompter
         * @param {number} accountId Account identifier
         * @param {number} prompterId Prompter identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPrompter(accountId, prompterId, options) {
            return PrompterApiFp(configuration).getPrompter(accountId, prompterId, options)(axios, basePath);
        },
        /**
         * Get prompters
         * @param {number} accountId Account identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPrompters(accountId, options) {
            return PrompterApiFp(configuration).getPrompters(accountId, options)(axios, basePath);
        },
        /**
         * Remove prompter
         * @param {number} accountId Account identifier
         * @param {number} prompterId Prompter identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removePrompter(accountId, prompterId, options) {
            return PrompterApiFp(configuration).removePrompter(accountId, prompterId, options)(axios, basePath);
        },
        /**
         * Update prompter
         * @param {number} accountId Account identifier
         * @param {number} prompterId Prompter identifier
         * @param {UpdatePrompterDto} updatePrompterDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePrompter(accountId, prompterId, updatePrompterDto, options) {
            return PrompterApiFp(configuration).updatePrompter(accountId, prompterId, updatePrompterDto, options)(axios, basePath);
        },
    };
};
/**
 * PrompterApi - object-oriented interface
 * @export
 * @class PrompterApi
 * @extends {BaseAPI}
 */
export class PrompterApi extends BaseAPI {
    /**
     * Create prompter
     * @param {number} accountId Account identifier
     * @param {CreatePrompterDto} createPrompterDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PrompterApi
     */
    createPrompter(accountId, createPrompterDto, options) {
        return PrompterApiFp(this.configuration).createPrompter(accountId, createPrompterDto, options)(this.axios, this.basePath);
    }
    /**
     * Get prompter
     * @param {number} accountId Account identifier
     * @param {number} prompterId Prompter identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PrompterApi
     */
    getPrompter(accountId, prompterId, options) {
        return PrompterApiFp(this.configuration).getPrompter(accountId, prompterId, options)(this.axios, this.basePath);
    }
    /**
     * Get prompters
     * @param {number} accountId Account identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PrompterApi
     */
    getPrompters(accountId, options) {
        return PrompterApiFp(this.configuration).getPrompters(accountId, options)(this.axios, this.basePath);
    }
    /**
     * Remove prompter
     * @param {number} accountId Account identifier
     * @param {number} prompterId Prompter identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PrompterApi
     */
    removePrompter(accountId, prompterId, options) {
        return PrompterApiFp(this.configuration).removePrompter(accountId, prompterId, options)(this.axios, this.basePath);
    }
    /**
     * Update prompter
     * @param {number} accountId Account identifier
     * @param {number} prompterId Prompter identifier
     * @param {UpdatePrompterDto} updatePrompterDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PrompterApi
     */
    updatePrompter(accountId, prompterId, updatePrompterDto, options) {
        return PrompterApiFp(this.configuration).updatePrompter(accountId, prompterId, updatePrompterDto, options)(this.axios, this.basePath);
    }
}
/**
 * SoundNotificationApi - axios parameter creator
 * @export
 */
export const SoundNotificationApiAxiosParamCreator = function (configuration) {
    return {
        /**
         * Get all sound notifications
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllSoundNotifications(options = {}) {
            const localVarPath = `/api/aimychat-be/notification-sounds`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * SoundNotificationApi - functional programming interface
 * @export
 */
export const SoundNotificationApiFp = function (configuration) {
    return {
        /**
         * Get all sound notifications
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllSoundNotifications(options) {
            const localVarAxiosArgs = SoundNotificationApiAxiosParamCreator(configuration).getAllSoundNotifications(options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
    };
};
/**
 * SoundNotificationApi - factory interface
 * @export
 */
export const SoundNotificationApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         * Get all sound notifications
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllSoundNotifications(options) {
            return SoundNotificationApiFp(configuration).getAllSoundNotifications(options)(axios, basePath);
        },
    };
};
/**
 * SoundNotificationApi - object-oriented interface
 * @export
 * @class SoundNotificationApi
 * @extends {BaseAPI}
 */
export class SoundNotificationApi extends BaseAPI {
    /**
     * Get all sound notifications
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SoundNotificationApi
     */
    getAllSoundNotifications(options) {
        return SoundNotificationApiFp(this.configuration).getAllSoundNotifications(options)(this.axios, this.basePath);
    }
}
/**
 * StatisticsApi - axios parameter creator
 * @export
 */
export const StatisticsApiAxiosParamCreator = function (configuration) {
    return {
        /**
         * Get chart for operator place
         * @param {number} accountId Account identifier
         * @param {Date} dateFrom The date-time from which the stats will be built.
         * @param {Date} dateTo The date by which the stats will be built.
         * @param {ChartType} chartType The type of building chart
         * @param {IntervalType} intervalType Interval with which statistics will be generated
         * @param {number} [timezone] Timezone offset
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCommonChart(accountId, dateFrom, dateTo, chartType, intervalType, timezone, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling getCommonChart.");
            }
            // verify required parameter 'dateFrom' is not null or undefined
            if (dateFrom === null || dateFrom === undefined) {
                throw new RequiredError("dateFrom", "Required parameter dateFrom was null or undefined when calling getCommonChart.");
            }
            // verify required parameter 'dateTo' is not null or undefined
            if (dateTo === null || dateTo === undefined) {
                throw new RequiredError("dateTo", "Required parameter dateTo was null or undefined when calling getCommonChart.");
            }
            // verify required parameter 'chartType' is not null or undefined
            if (chartType === null || chartType === undefined) {
                throw new RequiredError("chartType", "Required parameter chartType was null or undefined when calling getCommonChart.");
            }
            // verify required parameter 'intervalType' is not null or undefined
            if (intervalType === null || intervalType === undefined) {
                throw new RequiredError("intervalType", "Required parameter intervalType was null or undefined when calling getCommonChart.");
            }
            const localVarPath = `/api/aimychat-be/accounts/{accountId}/statistics/common/chart`.replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            if (dateFrom !== undefined) {
                localVarQueryParameter["dateFrom"] =
                    dateFrom instanceof Date
                        ? dateFrom.toISOString()
                        : dateFrom;
            }
            if (dateTo !== undefined) {
                localVarQueryParameter["dateTo"] =
                    dateTo instanceof Date
                        ? dateTo.toISOString()
                        : dateTo;
            }
            if (chartType !== undefined) {
                localVarQueryParameter["chartType"] = chartType;
            }
            if (intervalType !== undefined) {
                localVarQueryParameter["intervalType"] = intervalType;
            }
            if (timezone !== undefined && timezone !== null) {
                localVarHeaderParameter["Timezone"] = String(JSON.stringify(timezone));
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get summary statistics for operator
         * @param {number} accountId Account identifier
         * @param {Date} dateFrom The date-time from which the stats will be built.
         * @param {Date} dateTo The date by which the stats will be built.
         * @param {number} [timezone] Timezone offset
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCommonSummary(accountId, dateFrom, dateTo, timezone, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling getCommonSummary.");
            }
            // verify required parameter 'dateFrom' is not null or undefined
            if (dateFrom === null || dateFrom === undefined) {
                throw new RequiredError("dateFrom", "Required parameter dateFrom was null or undefined when calling getCommonSummary.");
            }
            // verify required parameter 'dateTo' is not null or undefined
            if (dateTo === null || dateTo === undefined) {
                throw new RequiredError("dateTo", "Required parameter dateTo was null or undefined when calling getCommonSummary.");
            }
            const localVarPath = `/api/aimychat-be/accounts/{accountId}/statistics/common/summary`.replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            if (dateFrom !== undefined) {
                localVarQueryParameter["dateFrom"] =
                    dateFrom instanceof Date
                        ? dateFrom.toISOString()
                        : dateFrom;
            }
            if (dateTo !== undefined) {
                localVarQueryParameter["dateTo"] =
                    dateTo instanceof Date
                        ? dateTo.toISOString()
                        : dateTo;
            }
            if (timezone !== undefined && timezone !== null) {
                localVarHeaderParameter["Timezone"] = String(JSON.stringify(timezone));
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get chart for operator\'s chats
         * @param {number} accountId Account identifier
         * @param {number} operatorId Operator identifier
         * @param {Date} dateFrom The date-time from which the stats will be built.
         * @param {Date} dateTo The date by which the stats will be built.
         * @param {ChartType} chartType The type of building chart
         * @param {IntervalType} intervalType Interval with which statistics will be generated
         * @param {number} [timezone] Timezone offset
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOperatorChart(accountId, operatorId, dateFrom, dateTo, chartType, intervalType, timezone, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling getOperatorChart.");
            }
            // verify required parameter 'operatorId' is not null or undefined
            if (operatorId === null || operatorId === undefined) {
                throw new RequiredError("operatorId", "Required parameter operatorId was null or undefined when calling getOperatorChart.");
            }
            // verify required parameter 'dateFrom' is not null or undefined
            if (dateFrom === null || dateFrom === undefined) {
                throw new RequiredError("dateFrom", "Required parameter dateFrom was null or undefined when calling getOperatorChart.");
            }
            // verify required parameter 'dateTo' is not null or undefined
            if (dateTo === null || dateTo === undefined) {
                throw new RequiredError("dateTo", "Required parameter dateTo was null or undefined when calling getOperatorChart.");
            }
            // verify required parameter 'chartType' is not null or undefined
            if (chartType === null || chartType === undefined) {
                throw new RequiredError("chartType", "Required parameter chartType was null or undefined when calling getOperatorChart.");
            }
            // verify required parameter 'intervalType' is not null or undefined
            if (intervalType === null || intervalType === undefined) {
                throw new RequiredError("intervalType", "Required parameter intervalType was null or undefined when calling getOperatorChart.");
            }
            const localVarPath = `/api/aimychat-be/accounts/{accountId}/statistics/operator/{operatorId}/chart`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"operatorId"}}`, encodeURIComponent(String(operatorId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            if (dateFrom !== undefined) {
                localVarQueryParameter["dateFrom"] =
                    dateFrom instanceof Date
                        ? dateFrom.toISOString()
                        : dateFrom;
            }
            if (dateTo !== undefined) {
                localVarQueryParameter["dateTo"] =
                    dateTo instanceof Date
                        ? dateTo.toISOString()
                        : dateTo;
            }
            if (chartType !== undefined) {
                localVarQueryParameter["chartType"] = chartType;
            }
            if (intervalType !== undefined) {
                localVarQueryParameter["intervalType"] = intervalType;
            }
            if (timezone !== undefined && timezone !== null) {
                localVarHeaderParameter["Timezone"] = String(JSON.stringify(timezone));
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get chart for operator group\'s chats
         * @param {number} accountId Account identifier
         * @param {number} operatorGroupId Operator group identifier
         * @param {Date} dateFrom The date-time from which the stats will be built.
         * @param {Date} dateTo The date by which the stats will be built.
         * @param {ChartType} chartType The type of building chart
         * @param {IntervalType} intervalType Interval with which statistics will be generated
         * @param {number} [timezone] Timezone offset
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOperatorGroupChart(accountId, operatorGroupId, dateFrom, dateTo, chartType, intervalType, timezone, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling getOperatorGroupChart.");
            }
            // verify required parameter 'operatorGroupId' is not null or undefined
            if (operatorGroupId === null || operatorGroupId === undefined) {
                throw new RequiredError("operatorGroupId", "Required parameter operatorGroupId was null or undefined when calling getOperatorGroupChart.");
            }
            // verify required parameter 'dateFrom' is not null or undefined
            if (dateFrom === null || dateFrom === undefined) {
                throw new RequiredError("dateFrom", "Required parameter dateFrom was null or undefined when calling getOperatorGroupChart.");
            }
            // verify required parameter 'dateTo' is not null or undefined
            if (dateTo === null || dateTo === undefined) {
                throw new RequiredError("dateTo", "Required parameter dateTo was null or undefined when calling getOperatorGroupChart.");
            }
            // verify required parameter 'chartType' is not null or undefined
            if (chartType === null || chartType === undefined) {
                throw new RequiredError("chartType", "Required parameter chartType was null or undefined when calling getOperatorGroupChart.");
            }
            // verify required parameter 'intervalType' is not null or undefined
            if (intervalType === null || intervalType === undefined) {
                throw new RequiredError("intervalType", "Required parameter intervalType was null or undefined when calling getOperatorGroupChart.");
            }
            const localVarPath = `/api/aimychat-be/accounts/{accountId}/statistics/operator-group/{operatorGroupId}/chart`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"operatorGroupId"}}`, encodeURIComponent(String(operatorGroupId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            if (dateFrom !== undefined) {
                localVarQueryParameter["dateFrom"] =
                    dateFrom instanceof Date
                        ? dateFrom.toISOString()
                        : dateFrom;
            }
            if (dateTo !== undefined) {
                localVarQueryParameter["dateTo"] =
                    dateTo instanceof Date
                        ? dateTo.toISOString()
                        : dateTo;
            }
            if (chartType !== undefined) {
                localVarQueryParameter["chartType"] = chartType;
            }
            if (intervalType !== undefined) {
                localVarQueryParameter["intervalType"] = intervalType;
            }
            if (timezone !== undefined && timezone !== null) {
                localVarHeaderParameter["Timezone"] = String(JSON.stringify(timezone));
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get summary statistics for operator
         * @param {number} accountId Account identifier
         * @param {number} operatorGroupId Operator group identifier
         * @param {Date} dateFrom The date-time from which the stats will be built.
         * @param {Date} dateTo The date by which the stats will be built.
         * @param {number} [timezone] Timezone offset
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOperatorGroupSummary(accountId, operatorGroupId, dateFrom, dateTo, timezone, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling getOperatorGroupSummary.");
            }
            // verify required parameter 'operatorGroupId' is not null or undefined
            if (operatorGroupId === null || operatorGroupId === undefined) {
                throw new RequiredError("operatorGroupId", "Required parameter operatorGroupId was null or undefined when calling getOperatorGroupSummary.");
            }
            // verify required parameter 'dateFrom' is not null or undefined
            if (dateFrom === null || dateFrom === undefined) {
                throw new RequiredError("dateFrom", "Required parameter dateFrom was null or undefined when calling getOperatorGroupSummary.");
            }
            // verify required parameter 'dateTo' is not null or undefined
            if (dateTo === null || dateTo === undefined) {
                throw new RequiredError("dateTo", "Required parameter dateTo was null or undefined when calling getOperatorGroupSummary.");
            }
            const localVarPath = `/api/aimychat-be/accounts/{accountId}/statistics/operator-group/{operatorGroupId}/summary`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"operatorGroupId"}}`, encodeURIComponent(String(operatorGroupId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            if (dateFrom !== undefined) {
                localVarQueryParameter["dateFrom"] =
                    dateFrom instanceof Date
                        ? dateFrom.toISOString()
                        : dateFrom;
            }
            if (dateTo !== undefined) {
                localVarQueryParameter["dateTo"] =
                    dateTo instanceof Date
                        ? dateTo.toISOString()
                        : dateTo;
            }
            if (timezone !== undefined && timezone !== null) {
                localVarHeaderParameter["Timezone"] = String(JSON.stringify(timezone));
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get summary statistics for operator
         * @param {number} accountId Account identifier
         * @param {number} operatorId Operator identifier
         * @param {Date} dateFrom The date-time from which the stats will be built.
         * @param {Date} dateTo The date by which the stats will be built.
         * @param {number} [timezone] Timezone offset
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOperatorSummary(accountId, operatorId, dateFrom, dateTo, timezone, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling getOperatorSummary.");
            }
            // verify required parameter 'operatorId' is not null or undefined
            if (operatorId === null || operatorId === undefined) {
                throw new RequiredError("operatorId", "Required parameter operatorId was null or undefined when calling getOperatorSummary.");
            }
            // verify required parameter 'dateFrom' is not null or undefined
            if (dateFrom === null || dateFrom === undefined) {
                throw new RequiredError("dateFrom", "Required parameter dateFrom was null or undefined when calling getOperatorSummary.");
            }
            // verify required parameter 'dateTo' is not null or undefined
            if (dateTo === null || dateTo === undefined) {
                throw new RequiredError("dateTo", "Required parameter dateTo was null or undefined when calling getOperatorSummary.");
            }
            const localVarPath = `/api/aimychat-be/accounts/{accountId}/statistics/operator/{operatorId}/summary`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"operatorId"}}`, encodeURIComponent(String(operatorId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            if (dateFrom !== undefined) {
                localVarQueryParameter["dateFrom"] =
                    dateFrom instanceof Date
                        ? dateFrom.toISOString()
                        : dateFrom;
            }
            if (dateTo !== undefined) {
                localVarQueryParameter["dateTo"] =
                    dateTo instanceof Date
                        ? dateTo.toISOString()
                        : dateTo;
            }
            if (timezone !== undefined && timezone !== null) {
                localVarHeaderParameter["Timezone"] = String(JSON.stringify(timezone));
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get summary transfer topics statistics for operator place
         * @param {number} accountId Account identifier
         * @param {Date} dateFrom The date-time from which the stats will be built.
         * @param {Date} dateTo The date by which the stats will be built.
         * @param {number} [timezone] Timezone offset
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTransferTopicsStatistics(accountId, dateFrom, dateTo, timezone, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling getTransferTopicsStatistics.");
            }
            // verify required parameter 'dateFrom' is not null or undefined
            if (dateFrom === null || dateFrom === undefined) {
                throw new RequiredError("dateFrom", "Required parameter dateFrom was null or undefined when calling getTransferTopicsStatistics.");
            }
            // verify required parameter 'dateTo' is not null or undefined
            if (dateTo === null || dateTo === undefined) {
                throw new RequiredError("dateTo", "Required parameter dateTo was null or undefined when calling getTransferTopicsStatistics.");
            }
            const localVarPath = `/api/aimychat-be/accounts/{accountId}/statistics/common/themes`.replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            if (dateFrom !== undefined) {
                localVarQueryParameter["dateFrom"] =
                    dateFrom instanceof Date
                        ? dateFrom.toISOString()
                        : dateFrom;
            }
            if (dateTo !== undefined) {
                localVarQueryParameter["dateTo"] =
                    dateTo instanceof Date
                        ? dateTo.toISOString()
                        : dateTo;
            }
            if (timezone !== undefined && timezone !== null) {
                localVarHeaderParameter["Timezone"] = String(JSON.stringify(timezone));
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * StatisticsApi - functional programming interface
 * @export
 */
export const StatisticsApiFp = function (configuration) {
    return {
        /**
         * Get chart for operator place
         * @param {number} accountId Account identifier
         * @param {Date} dateFrom The date-time from which the stats will be built.
         * @param {Date} dateTo The date by which the stats will be built.
         * @param {ChartType} chartType The type of building chart
         * @param {IntervalType} intervalType Interval with which statistics will be generated
         * @param {number} [timezone] Timezone offset
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCommonChart(accountId, dateFrom, dateTo, chartType, intervalType, timezone, options) {
            const localVarAxiosArgs = StatisticsApiAxiosParamCreator(configuration).getCommonChart(accountId, dateFrom, dateTo, chartType, intervalType, timezone, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Get summary statistics for operator
         * @param {number} accountId Account identifier
         * @param {Date} dateFrom The date-time from which the stats will be built.
         * @param {Date} dateTo The date by which the stats will be built.
         * @param {number} [timezone] Timezone offset
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCommonSummary(accountId, dateFrom, dateTo, timezone, options) {
            const localVarAxiosArgs = StatisticsApiAxiosParamCreator(configuration).getCommonSummary(accountId, dateFrom, dateTo, timezone, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Get chart for operator\'s chats
         * @param {number} accountId Account identifier
         * @param {number} operatorId Operator identifier
         * @param {Date} dateFrom The date-time from which the stats will be built.
         * @param {Date} dateTo The date by which the stats will be built.
         * @param {ChartType} chartType The type of building chart
         * @param {IntervalType} intervalType Interval with which statistics will be generated
         * @param {number} [timezone] Timezone offset
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOperatorChart(accountId, operatorId, dateFrom, dateTo, chartType, intervalType, timezone, options) {
            const localVarAxiosArgs = StatisticsApiAxiosParamCreator(configuration).getOperatorChart(accountId, operatorId, dateFrom, dateTo, chartType, intervalType, timezone, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Get chart for operator group\'s chats
         * @param {number} accountId Account identifier
         * @param {number} operatorGroupId Operator group identifier
         * @param {Date} dateFrom The date-time from which the stats will be built.
         * @param {Date} dateTo The date by which the stats will be built.
         * @param {ChartType} chartType The type of building chart
         * @param {IntervalType} intervalType Interval with which statistics will be generated
         * @param {number} [timezone] Timezone offset
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOperatorGroupChart(accountId, operatorGroupId, dateFrom, dateTo, chartType, intervalType, timezone, options) {
            const localVarAxiosArgs = StatisticsApiAxiosParamCreator(configuration).getOperatorGroupChart(accountId, operatorGroupId, dateFrom, dateTo, chartType, intervalType, timezone, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Get summary statistics for operator
         * @param {number} accountId Account identifier
         * @param {number} operatorGroupId Operator group identifier
         * @param {Date} dateFrom The date-time from which the stats will be built.
         * @param {Date} dateTo The date by which the stats will be built.
         * @param {number} [timezone] Timezone offset
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOperatorGroupSummary(accountId, operatorGroupId, dateFrom, dateTo, timezone, options) {
            const localVarAxiosArgs = StatisticsApiAxiosParamCreator(configuration).getOperatorGroupSummary(accountId, operatorGroupId, dateFrom, dateTo, timezone, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Get summary statistics for operator
         * @param {number} accountId Account identifier
         * @param {number} operatorId Operator identifier
         * @param {Date} dateFrom The date-time from which the stats will be built.
         * @param {Date} dateTo The date by which the stats will be built.
         * @param {number} [timezone] Timezone offset
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOperatorSummary(accountId, operatorId, dateFrom, dateTo, timezone, options) {
            const localVarAxiosArgs = StatisticsApiAxiosParamCreator(configuration).getOperatorSummary(accountId, operatorId, dateFrom, dateTo, timezone, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Get summary transfer topics statistics for operator place
         * @param {number} accountId Account identifier
         * @param {Date} dateFrom The date-time from which the stats will be built.
         * @param {Date} dateTo The date by which the stats will be built.
         * @param {number} [timezone] Timezone offset
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTransferTopicsStatistics(accountId, dateFrom, dateTo, timezone, options) {
            const localVarAxiosArgs = StatisticsApiAxiosParamCreator(configuration).getTransferTopicsStatistics(accountId, dateFrom, dateTo, timezone, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
    };
};
/**
 * StatisticsApi - factory interface
 * @export
 */
export const StatisticsApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         * Get chart for operator place
         * @param {number} accountId Account identifier
         * @param {Date} dateFrom The date-time from which the stats will be built.
         * @param {Date} dateTo The date by which the stats will be built.
         * @param {ChartType} chartType The type of building chart
         * @param {IntervalType} intervalType Interval with which statistics will be generated
         * @param {number} [timezone] Timezone offset
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCommonChart(accountId, dateFrom, dateTo, chartType, intervalType, timezone, options) {
            return StatisticsApiFp(configuration).getCommonChart(accountId, dateFrom, dateTo, chartType, intervalType, timezone, options)(axios, basePath);
        },
        /**
         * Get summary statistics for operator
         * @param {number} accountId Account identifier
         * @param {Date} dateFrom The date-time from which the stats will be built.
         * @param {Date} dateTo The date by which the stats will be built.
         * @param {number} [timezone] Timezone offset
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCommonSummary(accountId, dateFrom, dateTo, timezone, options) {
            return StatisticsApiFp(configuration).getCommonSummary(accountId, dateFrom, dateTo, timezone, options)(axios, basePath);
        },
        /**
         * Get chart for operator\'s chats
         * @param {number} accountId Account identifier
         * @param {number} operatorId Operator identifier
         * @param {Date} dateFrom The date-time from which the stats will be built.
         * @param {Date} dateTo The date by which the stats will be built.
         * @param {ChartType} chartType The type of building chart
         * @param {IntervalType} intervalType Interval with which statistics will be generated
         * @param {number} [timezone] Timezone offset
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOperatorChart(accountId, operatorId, dateFrom, dateTo, chartType, intervalType, timezone, options) {
            return StatisticsApiFp(configuration).getOperatorChart(accountId, operatorId, dateFrom, dateTo, chartType, intervalType, timezone, options)(axios, basePath);
        },
        /**
         * Get chart for operator group\'s chats
         * @param {number} accountId Account identifier
         * @param {number} operatorGroupId Operator group identifier
         * @param {Date} dateFrom The date-time from which the stats will be built.
         * @param {Date} dateTo The date by which the stats will be built.
         * @param {ChartType} chartType The type of building chart
         * @param {IntervalType} intervalType Interval with which statistics will be generated
         * @param {number} [timezone] Timezone offset
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOperatorGroupChart(accountId, operatorGroupId, dateFrom, dateTo, chartType, intervalType, timezone, options) {
            return StatisticsApiFp(configuration).getOperatorGroupChart(accountId, operatorGroupId, dateFrom, dateTo, chartType, intervalType, timezone, options)(axios, basePath);
        },
        /**
         * Get summary statistics for operator
         * @param {number} accountId Account identifier
         * @param {number} operatorGroupId Operator group identifier
         * @param {Date} dateFrom The date-time from which the stats will be built.
         * @param {Date} dateTo The date by which the stats will be built.
         * @param {number} [timezone] Timezone offset
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOperatorGroupSummary(accountId, operatorGroupId, dateFrom, dateTo, timezone, options) {
            return StatisticsApiFp(configuration).getOperatorGroupSummary(accountId, operatorGroupId, dateFrom, dateTo, timezone, options)(axios, basePath);
        },
        /**
         * Get summary statistics for operator
         * @param {number} accountId Account identifier
         * @param {number} operatorId Operator identifier
         * @param {Date} dateFrom The date-time from which the stats will be built.
         * @param {Date} dateTo The date by which the stats will be built.
         * @param {number} [timezone] Timezone offset
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOperatorSummary(accountId, operatorId, dateFrom, dateTo, timezone, options) {
            return StatisticsApiFp(configuration).getOperatorSummary(accountId, operatorId, dateFrom, dateTo, timezone, options)(axios, basePath);
        },
        /**
         * Get summary transfer topics statistics for operator place
         * @param {number} accountId Account identifier
         * @param {Date} dateFrom The date-time from which the stats will be built.
         * @param {Date} dateTo The date by which the stats will be built.
         * @param {number} [timezone] Timezone offset
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTransferTopicsStatistics(accountId, dateFrom, dateTo, timezone, options) {
            return StatisticsApiFp(configuration).getTransferTopicsStatistics(accountId, dateFrom, dateTo, timezone, options)(axios, basePath);
        },
    };
};
/**
 * StatisticsApi - object-oriented interface
 * @export
 * @class StatisticsApi
 * @extends {BaseAPI}
 */
export class StatisticsApi extends BaseAPI {
    /**
     * Get chart for operator place
     * @param {number} accountId Account identifier
     * @param {Date} dateFrom The date-time from which the stats will be built.
     * @param {Date} dateTo The date by which the stats will be built.
     * @param {ChartType} chartType The type of building chart
     * @param {IntervalType} intervalType Interval with which statistics will be generated
     * @param {number} [timezone] Timezone offset
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatisticsApi
     */
    getCommonChart(accountId, dateFrom, dateTo, chartType, intervalType, timezone, options) {
        return StatisticsApiFp(this.configuration).getCommonChart(accountId, dateFrom, dateTo, chartType, intervalType, timezone, options)(this.axios, this.basePath);
    }
    /**
     * Get summary statistics for operator
     * @param {number} accountId Account identifier
     * @param {Date} dateFrom The date-time from which the stats will be built.
     * @param {Date} dateTo The date by which the stats will be built.
     * @param {number} [timezone] Timezone offset
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatisticsApi
     */
    getCommonSummary(accountId, dateFrom, dateTo, timezone, options) {
        return StatisticsApiFp(this.configuration).getCommonSummary(accountId, dateFrom, dateTo, timezone, options)(this.axios, this.basePath);
    }
    /**
     * Get chart for operator\'s chats
     * @param {number} accountId Account identifier
     * @param {number} operatorId Operator identifier
     * @param {Date} dateFrom The date-time from which the stats will be built.
     * @param {Date} dateTo The date by which the stats will be built.
     * @param {ChartType} chartType The type of building chart
     * @param {IntervalType} intervalType Interval with which statistics will be generated
     * @param {number} [timezone] Timezone offset
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatisticsApi
     */
    getOperatorChart(accountId, operatorId, dateFrom, dateTo, chartType, intervalType, timezone, options) {
        return StatisticsApiFp(this.configuration).getOperatorChart(accountId, operatorId, dateFrom, dateTo, chartType, intervalType, timezone, options)(this.axios, this.basePath);
    }
    /**
     * Get chart for operator group\'s chats
     * @param {number} accountId Account identifier
     * @param {number} operatorGroupId Operator group identifier
     * @param {Date} dateFrom The date-time from which the stats will be built.
     * @param {Date} dateTo The date by which the stats will be built.
     * @param {ChartType} chartType The type of building chart
     * @param {IntervalType} intervalType Interval with which statistics will be generated
     * @param {number} [timezone] Timezone offset
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatisticsApi
     */
    getOperatorGroupChart(accountId, operatorGroupId, dateFrom, dateTo, chartType, intervalType, timezone, options) {
        return StatisticsApiFp(this.configuration).getOperatorGroupChart(accountId, operatorGroupId, dateFrom, dateTo, chartType, intervalType, timezone, options)(this.axios, this.basePath);
    }
    /**
     * Get summary statistics for operator
     * @param {number} accountId Account identifier
     * @param {number} operatorGroupId Operator group identifier
     * @param {Date} dateFrom The date-time from which the stats will be built.
     * @param {Date} dateTo The date by which the stats will be built.
     * @param {number} [timezone] Timezone offset
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatisticsApi
     */
    getOperatorGroupSummary(accountId, operatorGroupId, dateFrom, dateTo, timezone, options) {
        return StatisticsApiFp(this.configuration).getOperatorGroupSummary(accountId, operatorGroupId, dateFrom, dateTo, timezone, options)(this.axios, this.basePath);
    }
    /**
     * Get summary statistics for operator
     * @param {number} accountId Account identifier
     * @param {number} operatorId Operator identifier
     * @param {Date} dateFrom The date-time from which the stats will be built.
     * @param {Date} dateTo The date by which the stats will be built.
     * @param {number} [timezone] Timezone offset
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatisticsApi
     */
    getOperatorSummary(accountId, operatorId, dateFrom, dateTo, timezone, options) {
        return StatisticsApiFp(this.configuration).getOperatorSummary(accountId, operatorId, dateFrom, dateTo, timezone, options)(this.axios, this.basePath);
    }
    /**
     * Get summary transfer topics statistics for operator place
     * @param {number} accountId Account identifier
     * @param {Date} dateFrom The date-time from which the stats will be built.
     * @param {Date} dateTo The date by which the stats will be built.
     * @param {number} [timezone] Timezone offset
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatisticsApi
     */
    getTransferTopicsStatistics(accountId, dateFrom, dateTo, timezone, options) {
        return StatisticsApiFp(this.configuration).getTransferTopicsStatistics(accountId, dateFrom, dateTo, timezone, options)(this.axios, this.basePath);
    }
}
/**
 * SystemApi - axios parameter creator
 * @export
 */
export const SystemApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @summary Service health check
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthCheck(options = {}) {
            const localVarPath = `/healthCheck`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Service version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        version(options = {}) {
            const localVarPath = `/version`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * SystemApi - functional programming interface
 * @export
 */
export const SystemApiFp = function (configuration) {
    return {
        /**
         *
         * @summary Service health check
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthCheck(options) {
            const localVarAxiosArgs = SystemApiAxiosParamCreator(configuration).healthCheck(options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Service version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        version(options) {
            const localVarAxiosArgs = SystemApiAxiosParamCreator(configuration).version(options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
    };
};
/**
 * SystemApi - factory interface
 * @export
 */
export const SystemApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         *
         * @summary Service health check
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthCheck(options) {
            return SystemApiFp(configuration).healthCheck(options)(axios, basePath);
        },
        /**
         *
         * @summary Service version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        version(options) {
            return SystemApiFp(configuration).version(options)(axios, basePath);
        },
    };
};
/**
 * SystemApi - object-oriented interface
 * @export
 * @class SystemApi
 * @extends {BaseAPI}
 */
export class SystemApi extends BaseAPI {
    /**
     *
     * @summary Service health check
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemApi
     */
    healthCheck(options) {
        return SystemApiFp(this.configuration).healthCheck(options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Service version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemApi
     */
    version(options) {
        return SystemApiFp(this.configuration).version(options)(this.axios, this.basePath);
    }
}
